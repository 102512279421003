.fhfh{
    margin-bottom: 2rem;
    overflow-x: scroll;
}

.fhfh iframe{
    padding: 10px;
}
.fhfh::-webkit-scrollbar {
   display: none;
}
@media screen and (max-width: 1024px) {
    .fhfh {
        flex-direction: column;
        align-content: space-evenly;
        align-items: center;
        /* min-height: 690px; */
    }
}
@media screen and (max-width: 600px) {
    .fhfh>iframe{
        width: 80%;
    }
}
@media screen and (max-width: 425px) {
    .fhfh>iframe{
        height: 250px;
    }
    .fhfh{
        min-height: 550px;
    }
}
